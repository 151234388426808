import { VerifiedUser } from '@mui/icons-material'
import { ReactElement } from 'react'
import styled from 'styled-components'
import { Translate } from 'src/i18n'
import { VITE_EXPERIAN_AUTOCHECK_BASE_URL } from 'src/config/constants'
interface IExperianAutocheckButtonProps {
  vin?: string
}

export const ExperianAutocheckButton = (
  props: IExperianAutocheckButtonProps
): ReactElement => {
  const { vin } = props
  const vinParameter = vin ? `&vin=${vin}` : ''
  const url = VITE_EXPERIAN_AUTOCHECK_BASE_URL + vinParameter

  return (
    <Anchor href={url} target="_blank" rel="noopener noreferrer">
      <VerifiedUserIcon fontSize="inherit" />
      <Label>
        {Translate('experianAutocheck')}
        <sup>&reg;</sup>
      </Label>
    </Anchor>
  )
}

const Anchor = styled.a`
  display: flex;
  gap: 4px;
  margin: auto 0px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  color: ${(p) => p.theme.colors.link};
  cursor: pointer;
`

const VerifiedUserIcon = styled(VerifiedUser)`
  margin: auto 0px;
`

const Label = styled.span`
  margin: auto 0px;
  text-wrap: nowrap;
`
