import { show as showNiceModal } from '@ebay/nice-modal-react'
import { IS_HD_INTERCHANGE_FLOW_ENABLED } from 'src/config/ConfigManager'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { MiscPreferencesKeys } from 'src/store/user/UserStore'
import { PartTypeModal } from 'src/ui-components/modals/PartTypeModel'
import { parseBoolean } from 'src/ui-components/util/CommonFunctions'
import history from './history'

export const getInterchangeParts = (text: string): void => {
  if (text.length <= 3 || text.length > 14) {
    StoreInstances.uiStore.displayErrorNotification(
      'interchangeSearchTextError'
    )
    return
  }
  if (text.includes('*') || text.includes('?')) {
    StoreInstances.uiStore.displayErrorNotification(
      'wildCardSearchNotAllowedText'
    )
    return
  }
  StoreInstances.searchStore.lastInterchangeTextSearched = text
  if (IS_HD_INTERCHANGE_FLOW_ENABLED) {
    const isInterchangePartTypes =
      StoreInstances.userStore.miscPreferences[
        MiscPreferencesKeys.INTERCHANGE_PARTTYPES
      ] && false // MPV3-5810 - temporary disabled drawers - Nov 13th 2024, to get them back remove the '&& false'
    const isInterchangeManufacturersTypes =
      StoreInstances.userStore.miscPreferences[
        MiscPreferencesKeys.INTERCHANGE_MANUFACTURERS
      ] && false // MPV3-5810 - temporary disabled drawers - Nov 13th 2024, to get them back remove the '&& false'
    if (parseBoolean(isInterchangePartTypes))
      showNiceModal(PartTypeModal, {
        title: 'interchangeSearch',
        subtitle: text,
        modeltype: 'PartType',
      })
    else if (parseBoolean(isInterchangeManufacturersTypes)) {
      showNiceModal(PartTypeModal, {
        title: 'interchangeSearch',
        subtitle: text,
        modeltype: 'Manufacture',
      })
    } else {
      const trimmedText = text.trim()
      StoreInstances.uiStore.setSkipAddVehicleCallback(undefined)

      const search = () =>
        StoreInstances.searchStore.getInterchangeApiRequest(
          trimmedText,
          [],
          [],
          '',
          parseBoolean(
            StoreInstances.userStore.miscPreferences?.[
              MiscPreferencesKeys.INTERCHANGE_PARTTYPES
            ]
          ),
          parseBoolean(
            StoreInstances.userStore.miscPreferences?.[
              MiscPreferencesKeys.INTERCHANGE_MANUFACTURERS
            ]
          )
        )

      // StoreInstances.searchStore.handleAddBreadCrumb({
      //   page: 'interchange',
      //   breadcumb: trimmedText,
      //   url: search,
      // })

      search()
      history.push('/searchResults')
    }
  } else {
    const trimmedText = text.trim()
    StoreInstances.uiStore.setSkipAddVehicleCallback(undefined)

    const search = () =>
      StoreInstances.searchStore.getInterchangeApiRequest(
        trimmedText,
        [],
        [],
        '',
        parseBoolean(
          StoreInstances.userStore.miscPreferences?.[
            MiscPreferencesKeys.INTERCHANGE_PARTTYPES
          ]
        ),
        parseBoolean(
          StoreInstances.userStore.miscPreferences?.[
            MiscPreferencesKeys.INTERCHANGE_MANUFACTURERS
          ]
        )
      )

    StoreInstances.searchStore.handleAddBreadCrumb({
      page: 'interchange',
      breadcumb: trimmedText,
      url: search,
    })

    search()

    history.push('/searchResults')
  }
}
