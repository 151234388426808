import { ListItemIcon, Menu, MenuItem } from '@mui/material'
import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { UserAccessDetails } from 'src/api/user/interfaces'
import { IS_INTEGRATED_VERSION } from 'src/config/ConfigManager'
import { redirectToAllianceConnect } from 'src/helpers/redirectToAllianceConnect'
import { I18n } from 'src/i18n'
import UserInfoServiceProvider from 'src/services/UserInfoServiceProvider'
import { useSearchStore } from 'src/store/search/SearchStore'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'
import authManager from '../../../../../../api/security/Auth'
import { StorageKeys } from 'src/config/constants'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { options } from 'src/features/search/AllProductsSearch/constants'
import { ISelectedOption } from 'src/features/search/AllProductsSearch/LanguagesDropdown'
import { useUserStore } from 'src/store/user/UserStore'
import AttributesServiceProvider from 'src/services/AttributesServiceProvider'

const isImpersonating =
  localStorage.getItem(StorageKeys.AUTO_LOGIN_IMPERSONATION_KEY) === 'true'
const isSalespad = localStorage.getItem(StorageKeys.SALESPAD_KEY) === 'true'

interface AccountMenuProps {
  open: boolean
  parentRef: React.RefObject<HTMLElement>
  onClose: () => void
  accountName: string
}

const LangMenu = ({ anchorEl, handleClose }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      {options.map((option) => (
        <LangMenuItem
          onClick={() => {
            handleClose(option.key)
          }}
          key={option.key}
        >
          <LangListIcon>
            <FlagImg src={option.imgPath} alt="My place 4 parts logo" />
          </LangListIcon>
          <Label className="pl-3">
            <I18n name={option.label}></I18n>
          </Label>
        </LangMenuItem>
      ))}
    </Menu>
  )
}

export const ProfileMenu = ({
  open,
  parentRef,
  onClose,
  accountName,
}: AccountMenuProps): ReactElement => {
  const history = useHistory()
  const user = useUserStore()
  const searchStore = useSearchStore()
  const [userAccessDetails, setUserAccessDetails] =
    useState<UserAccessDetails>(undefined)
  const [openLnagMenu, setOpenLnagMenu] = useState(null)
  const [language, setLanguage] = useState<ISelectedOption>(
    options.find((item) => item.key === user.preferences.language) || options[0]
  )

  const redirectUser = (dashboard): void => {
    redirectToAllianceConnect(userAccessDetails.url, dashboard)
  }

  const redirectUserToLoginWidget = (): void => {
    const originToRedirect = localStorage.getItem(
      StorageKeys.AUTO_LOGIN_ORIGIN_KEY
    )
    if (originToRedirect) {
      window.location.href = `${originToRedirect}/?salespad=true`
    }
  }

  useEffect(() => {
    if (open && !IS_INTEGRATED_VERSION && !isSalespad && !isImpersonating) {
      UserInfoServiceProvider.getUserAccess(authManager.getUserId()).then(
        (data) => {
          setUserAccessDetails(data)
        }
      )
    }
  }, [open])

  const onChangeLanguage = (selectedLanguage: ISelectedOption) => {
    const reqData = [
      {
        uuid: 'DEFAULT_LANGUAGE',
        domId: Number(user.preferences.userId),
        domType: 5,
        attrValue: selectedLanguage.key,
      },
    ]

    AttributesServiceProvider.saveOrUpdateAttribute(reqData)
    setLanguage(selectedLanguage)
  }

  const handleMenuClose = (value) => {
    setOpenLnagMenu(null)
    const selectedLanguage = options.find((lang) => lang.key === value)

    // Trigger only if a valid language is found
    if (selectedLanguage) {
      onChangeLanguage(selectedLanguage)
    }
  }

  const handleSubmenuOpen = (event) => {
    setOpenLnagMenu(event.currentTarget)
  }

  return (
    <StyledMenu
      open={open}
      onClose={onClose}
      anchorEl={parentRef?.current}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <AccountMenuItem onClick={onClose}>
        <span className="material-icons" style={iconStyle}>
          account_circle
        </span>
        {accountName}
      </AccountMenuItem>

      <AccountMenuItem aria-haspopup="true" onClick={handleSubmenuOpen}>
        <AccountListItemIcon>
          <FlagImg src={language.imgPath} alt="My place 4 parts logo" />
        </AccountListItemIcon>
        <Label className="pl-3">
          <I18n name={language.label}></I18n>
        </Label>
        <ChevronRightIcon />
      </AccountMenuItem>
      <LangMenu anchorEl={openLnagMenu} handleClose={handleMenuClose} />

      {!IS_INTEGRATED_VERSION && !isSalespad && !isImpersonating && (
        <>
          {userAccessDetails?.isAccess && (
            <AccountMenuItem
              onClick={() => {
                onClose()
                redirectUser(false)
              }}
            >
              <BoxWrapper />
              <I18n name="allianceConnect" />
            </AccountMenuItem>
          )}

          <AccountMenuItem
            onClick={() => {
              onClose()
              redirectUser(true)
            }}
          >
            <DashboardWrapper />
            <I18n name="dashboard" />
          </AccountMenuItem>
        </>
      )}

      {isImpersonating && isSalespad && (
        <AccountMenuItem
          onClick={() => {
            onClose()
            redirectUserToLoginWidget()
          }}
        >
          <span className="material-icons" style={iconStyle}>
            compare_arrows
          </span>
          <I18n name="switchOrg" />
        </AccountMenuItem>
      )}

      {/* AES doesn't have login and logout */}
      {!IS_INTEGRATED_VERSION && (
        <AccountMenuItem
          onClick={() => {
            onClose()
            history.push('/signOut')
            searchStore.removeBreadCrumbs()
          }}
        >
          <span className="material-icons" style={iconStyle}>
            exit_to_app
          </span>
          <I18n name="logOut" />
        </AccountMenuItem>
      )}
    </StyledMenu>
  )
}

const StyledMenu = styled(Menu)`
  margin-top: 15px;
  @media screen and (max-width: ${breakpoints.tabletXLarge}px) {
    margin-top: 12px;
  }
`
const BoxWrapper = styled.div`
  background-color: ${defaultTheme.colors.orange};
  width: 18px;
  height: 18px;
  border: 4px solid rgba(0, 0, 0, 1);
  margin-right: 18px;
  margin-left: 2px;
`

const DashboardWrapper = styled.div`
  background-color: ${defaultTheme.colors.blueLight};
  width: 18px;
  height: 18px;
  border: 4px solid rgba(0, 0, 0, 1);
  margin-right: 18px;
  margin-left: 2px;
`

const iconStyle = {
  width: 24,
  height: 24,
  marginRight: 14,
}

const FlagImg = styled('img')`
  width: 20px;
`
const Label = styled('span')`
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #050505;
  width: 100%;
`
const LangMenuItem = styled(MenuItem)`
  width: 124px;
  height: 40px;
  padding: 8px 20px 8px 12px;
`
const AccountMenuItem = styled(MenuItem)`
  width: 247px;
  height: 40px;
  padding: 8px 16px 8px 16px;
`
const LangListIcon = styled(MenuItem)`
  min-width: 30px;
  padding: 0px;
`
const AccountListItemIcon = styled(ListItemIcon)`
  min-width: 34px !important;
`
