import { observer } from 'mobx-react-lite'
import { ReactElement, useEffect, useState } from 'react'
import { I18n, Translate } from 'src/i18n'
import { useListsStore } from 'src/store/lists/ListsStore'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { Button, Thumbnail } from 'src/ui-components'
import styled from 'styled-components'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { defaultTheme } from 'src/theme'
import BaseDrawer from 'src/ui-components/modals/BaseDrawer'

export const MoveGroupPartsDrawer = observer((): ReactElement => {
  const {
    showMoveToAnotherListDrawer,
    setMoveToAnotherListDrawer,
    setMoveToNextListDrawer,
  } = useUiStateStore()

  const {
    selectedItemsList,
    getMultipleMoveOrDeletePartsCount,
    multipleMoveOrDeleteParts,
  } = useListsStore()

  const [openGroups, setOpenGroups] = useState<{ [key: string]: boolean }>({})

  const moveItems = selectedItemsList?.filter((item) => item.isSelected)

  useEffect(() => {
    if (
      multipleMoveOrDeleteParts &&
      multipleMoveOrDeleteParts.length > 0 &&
      showMoveToAnotherListDrawer
    ) {
      const initialOpenGroups = multipleMoveOrDeleteParts.reduce(
        (acc, category) => {
          category.groups.forEach((group) => {
            acc[group.groupId] = true
          })
          return acc
        },
        {}
      )
      setOpenGroups(initialOpenGroups)
    }
  }, [multipleMoveOrDeleteParts, showMoveToAnotherListDrawer])

  const toggleGroupOpen = (groupId: string) => {
    setOpenGroups((prev) => ({
      ...prev,
      [groupId]: !prev[groupId],
    }))
  }

  const drawerTitle = `${Translate(
    'moveToAnotherList'
  )} (${getMultipleMoveOrDeletePartsCount()} ${Translate('items')})`

  const drawerBody = (
    <Container>
      {multipleMoveOrDeleteParts.map((category, catIndex) => (
        <Breadcrumb key={catIndex}>
          {category.groups.map((group) => (
            <GroupHeader key={group.groupId}>
              {group?.parts?.length > 0 && (
                <>
                  {openGroups[group.groupId] ? (
                    <ExpandLess
                      style={{ cursor: 'pointer' }}
                      onClick={() => toggleGroupOpen(group.groupId)}
                    />
                  ) : (
                    <ExpandMore
                      style={{ cursor: 'pointer' }}
                      onClick={() => toggleGroupOpen(group.groupId)}
                    />
                  )}

                  <BreadcrumbText>
                    {category.categoryName}
                    {'\u00a0'}
                  </BreadcrumbText>
                  <Divider>|</Divider>
                  <BreadcrumbText>
                    {'\u00a0'}
                    {category.listName}
                    {'\u00a0'}
                  </BreadcrumbText>
                  <Divider>|</Divider>
                  <BreadcrumbText>{group?.groupName}</BreadcrumbText>
                  <ItemsAmountSubtitle>
                    ({group?.parts?.length} items)
                  </ItemsAmountSubtitle>

                  {openGroups[group.groupId] && (
                    <>
                      <HeaderRow>
                        <ColHeader width="16.6%">
                          <I18n name="brand" />
                        </ColHeader>
                        <ColHeader width="16.6%">
                          <I18n name="MFR" />
                        </ColHeader>
                        <ColHeader width="16.6%">
                          <I18n name="PART #" />
                        </ColHeader>
                        <ColHeader width="16.6%">
                          <I18n name="QTY" />
                        </ColHeader>
                        <ColHeader width="16.6%">
                          <I18n name="description" />
                        </ColHeader>
                        <ColHeader width="16.6%">
                          <I18n name="COMMENTS" />
                        </ColHeader>
                      </HeaderRow>

                      {group.parts.map((item, index) => (
                        <InputWrapper key={index}>
                          <TextInputWrapper width="16.6%">
                            <Thumbnail
                              urlOrBase64=""
                              width={90}
                              height={90}
                              placeholder
                            />
                          </TextInputWrapper>
                          <TextInputWrapper width="16.6%">
                            <p>{item?.lineCode}</p>
                          </TextInputWrapper>
                          <TextInputWrapper width="16.6%">
                            <p>{item?.partNumber}</p>
                          </TextInputWrapper>
                          <TextInputWrapper width="16.6%">
                            <QuantityContent>{item?.stockQty}</QuantityContent>
                          </TextInputWrapper>
                          <TextInputWrapper width="16.6%">
                            <p>{item?.description}</p>
                          </TextInputWrapper>
                          <TextInputWrapper width="16.6%">
                            <p>{item?.comment}</p>
                          </TextInputWrapper>
                        </InputWrapper>
                      ))}
                    </>
                  )}
                </>
              )}
            </GroupHeader>
          ))}
        </Breadcrumb>
      ))}
    </Container>
  )

  const drawerFooter = (
    <BottomContainer>
      <ButtonsContainer>
        <Button
          text="cancel"
          onClick={() => {
            setMoveToAnotherListDrawer(false)
          }}
          variant="secondary"
        />

        {moveItems?.length > 0 && (
          <Button
            text={`${Translate('nextChooseList')}`}
            onClick={() => {
              setMoveToAnotherListDrawer(false)
              setMoveToNextListDrawer(true)
            }}
            variant="primary"
          />
        )}
      </ButtonsContainer>
    </BottomContainer>
  )

  return (
    <BaseDrawer
      open={showMoveToAnotherListDrawer}
      title={drawerTitle}
      body={drawerBody}
      onClose={() => setMoveToAnotherListDrawer(false)}
      footer={drawerFooter}
      width="800px"
    />
  )
})

const Container = styled.div`
  flex-direction: column;
`

const GroupHeader = styled.div`
  margin-top: 15px;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin: 10px 0px;
  align-items: center;
`

const BottomContainer = styled.div`
  height: 50px;
  width: 100%;
  background-color: ${defaultTheme.colors.white};
  justify-content: end;
  align-items: center;
  display: flex;
`

const Breadcrumb = styled.div`
  align-items: center;
`

const BreadcrumbText = styled.span`
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${defaultTheme.colors.primaryText};
  margin: 0px 8px 0px 8px;
`

const Divider = styled.span`
  color: ${defaultTheme.colors.borderTable};
`

const ItemsAmountSubtitle = styled.span`
  font-family: 'Oswald';
  font-weight: 450;
  font-size: 16px;
  color: ${defaultTheme.colors.greyLight};
`

const HeaderRow = styled.div`
  background: ${defaultTheme.colors.rowFeaturedBackground};
  box-shadow:
    inset 0px -1px 0px ${defaultTheme.colors.borderTable},
    inset 0px 1px 0px ${defaultTheme.colors.borderTable};

  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
  padding: 8px 32px;
`

interface HeaderRowProps {
  width?: string
}
const ColHeader = styled.div<HeaderRowProps>`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  display: flex;
  align-items: center;
  text-transform: uppercase;
  width: ${(p) => p.width};
  color: ${defaultTheme.colors.columnBorder};
  padding-right: 24px;
`

interface TextInputWrapperProps {
  width: string
}

const TextInputWrapper = styled.div<TextInputWrapperProps>`
  /* padding: 16px 16px 16px 0px; */
  width: ${(p) => p.width};
  display: flex;
`
const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  padding: 8px 32px;
  border-bottom: 1px solid ${defaultTheme.colors.borderTable};
`

const QuantityContent = styled.p`
  text-align: right;
  width: 27px;
`
