import { ReactElement, useCallback, useRef, useState, useEffect } from 'react'
import { Close as CloseIcon, ExpandLess, ExpandMore } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Drawer } from '@mui/material'
import { Thumbnail } from 'src/ui-components'
import { observer } from 'mobx-react-lite'
import { I18n } from 'src/i18n'
import { useListsStore } from 'src/store/lists/ListsStore'
import { defaultTheme } from 'src/theme'
import { breakpoints } from 'src/theme/breakpoints'
import styled from 'styled-components'
import { zIndex } from 'src/theme/zIndex'

export const DeleteItemsFromListsModal = observer((): ReactElement => {
  const ref = useRef<HTMLInputElement>(null)
  const listStore = useListsStore()

  const {
    showDeleteItemsFromListsModal,
    setShowDeleteItemsFromListsModal,
    onDeleteGroupPart,
    multipleMoveOrDeleteParts,
    getMultipleMoveOrDeletePartsCount,
  } = listStore

  const handleOnClose = useCallback(() => {
    setShowDeleteItemsFromListsModal(false)
  }, [setShowDeleteItemsFromListsModal])

  const [openGroups, setOpenGroups] = useState<{ [key: string]: boolean }>({})

  useEffect(() => {
    if (
      multipleMoveOrDeleteParts &&
      multipleMoveOrDeleteParts.length > 0 &&
      showDeleteItemsFromListsModal
    ) {
      const initialOpenGroups = multipleMoveOrDeleteParts.reduce(
        (acc, category) => {
          category.groups.forEach((group) => {
            acc[group.groupId] = true
          })
          return acc
        },
        {}
      )
      setOpenGroups(initialOpenGroups)
    }
  }, [multipleMoveOrDeleteParts, showDeleteItemsFromListsModal])

  const toggleGroupOpen = (groupId: string) => {
    setOpenGroups((prev) => ({
      ...prev,
      [groupId]: !prev[groupId],
    }))
  }

  const handleDeleteGroupParts = () => {
    const deletePartIds = multipleMoveOrDeleteParts.flatMap((category) =>
      category.groups.flatMap((group) => group.parts.map((part) => part.id))
    )
    onDeleteGroupPart(deletePartIds)
  }

  return (
    <Drawer
      anchor="right"
      open={showDeleteItemsFromListsModal}
      onClose={handleOnClose}
      style={{ width: '854px', zIndex: zIndex.modal }}
    >
      <Container ref={ref}>
        <Box
          height="100%"
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
        >
          <div>
            <TitleHeader>
              <Title>
                <I18n name="deleteItemFromList" />
                {'\u00a0'}
                <ItemsAmount>
                  ({getMultipleMoveOrDeletePartsCount()} items)
                </ItemsAmount>
              </Title>
              <StyledCloseIcon onClick={handleOnClose} />
            </TitleHeader>

            {multipleMoveOrDeleteParts.map((category, catIndex) => (
              <Breadcrumb key={catIndex}>
                {category.groups.map((group) => (
                  <GroupHeader key={group.groupId}>
                    {group?.parts?.length > 0 && (
                      <>
                        {openGroups[group.groupId] ? (
                          <ExpandLess
                            style={{ cursor: 'pointer' }}
                            onClick={() => toggleGroupOpen(group.groupId)}
                          />
                        ) : (
                          <ExpandMore
                            style={{ cursor: 'pointer' }}
                            onClick={() => toggleGroupOpen(group.groupId)}
                          />
                        )}

                        <BreadcrumbText>
                          {category.categoryName}
                          {'\u00a0'}
                        </BreadcrumbText>
                        <Divider>|</Divider>
                        <BreadcrumbText>
                          {'\u00a0'}
                          {category.listName}
                          {'\u00a0'}
                        </BreadcrumbText>
                        <Divider>|</Divider>
                        <BreadcrumbText>{group?.groupName}</BreadcrumbText>
                        <ItemsAmountSubtitle>
                          ({group?.parts?.length} items)
                        </ItemsAmountSubtitle>

                        {openGroups[group.groupId] && (
                          <>
                            <HeaderRow>
                              <ColHeader width="16.6%">
                                <I18n name="brand" />
                              </ColHeader>
                              <ColHeader width="16.6%">
                                <I18n name="MFR" />
                              </ColHeader>
                              <ColHeader width="16.6%">
                                <I18n name="PART #" />
                              </ColHeader>
                              <ColHeader width="16.6%">
                                <I18n name="QTY" />
                              </ColHeader>
                              <ColHeader width="16.6%">
                                <I18n name="description" />
                              </ColHeader>
                              <ColHeader width="16.6%">
                                <I18n name="COMMENTS" />
                              </ColHeader>
                            </HeaderRow>

                            {group.parts.map((item, index) => (
                              <InputWrapper key={index}>
                                <TextInputWrapper width="16.6%">
                                  <Thumbnail
                                    urlOrBase64=""
                                    width={90}
                                    height={90}
                                    placeholder
                                  />
                                </TextInputWrapper>
                                <TextInputWrapper width="16.6%">
                                  <p>{item?.lineCode}</p>
                                </TextInputWrapper>
                                <TextInputWrapper width="16.6%">
                                  <p>{item?.partNumber}</p>
                                </TextInputWrapper>
                                <TextInputWrapper width="16.6%">
                                  <QuantityContent>
                                    {item?.stockQty}
                                  </QuantityContent>
                                </TextInputWrapper>
                                <TextInputWrapper width="16.6%">
                                  <p>{item?.description}</p>
                                </TextInputWrapper>
                                <TextInputWrapper width="16.6%">
                                  <p>{item?.comment}</p>
                                </TextInputWrapper>
                              </InputWrapper>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </GroupHeader>
                ))}
              </Breadcrumb>
            ))}
          </div>

          <ButtonContainer>
            <ButtonCancel onClick={handleOnClose}>
              <I18n name="cancel" />
            </ButtonCancel>

            {getMultipleMoveOrDeletePartsCount() > 0 && (
              <Button type="submit">
                <ButtonContent
                  onClick={() => {
                    handleOnClose()
                    handleDeleteGroupParts()
                  }}
                >
                  <StyledDeleteIcon />
                  <MoveText>
                    <I18n name="delete" /> {'\u00a0'}{' '}
                    {getMultipleMoveOrDeletePartsCount()}
                    {'\u00a0'}
                    {getMultipleMoveOrDeletePartsCount() === 1 ? (
                      `Part`
                    ) : (
                      <I18n name="parts" />
                    )}
                    {'\u00a0'}
                  </MoveText>
                </ButtonContent>
              </Button>
            )}
          </ButtonContainer>
        </Box>
      </Container>
    </Drawer>
  )
})

interface TextInputWrapperProps {
  width: string
}

const TextInputWrapper = styled.div<TextInputWrapperProps>`
  /* padding: 16px 16px 16px 0px; */
  width: ${(p) => p.width};
  display: flex;
`

const GroupHeader = styled.div`
  margin-top: 15px;
`
const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  padding: 8px 32px;
  border-bottom: 1px solid ${defaultTheme.colors.borderTable};
`
const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 12px 24px;
  gap: 9px;
  width: 98%;
  height: 48px;
  background: ${defaultTheme.colors.red};
  border-radius: 3px;
  border: none;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    height: 36px;
    width: 179px;
    flex-direction: row;
    gap: 0px;
    padding: 0px;
    align-items: center;
    margin: 0px;
  }
`
const MoveText = styled.span`
  display: flex;
  @media screen and (min-width: ${breakpoints.laptop}px) {
    position: relative;
    top: 1px;
  }
  color: ${defaultTheme.colors.secondary};
  display: flex;
`

const ButtonContent = styled.h6`
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: white;
  text-decoration: uppercase;
  display: flex;
  @media screen and (min-width: ${breakpoints.laptop}px) {
    margin: 0px;
  }
`
const StyledDeleteIcon = styled(DeleteIcon)`
  cursor: pointer;

  margin: 2px 9px 3px 0px;
  color: ${defaultTheme.colors.secondary};

  @media screen and (min-width: ${breakpoints.laptop}px) {
    height: 22px;
    width: 22px;
  }
`

const ButtonCancel = styled(Button)`
  background-color: white;
  border-radius: 3px;
  border: 1px solid ${defaultTheme.colors.primaryText};
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${defaultTheme.colors.primaryText};
  margin-top: 16px;
  text-align: center;
  text-transform: uppercase;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    margin: 0px;
    width: 91px;
    height: 36px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.04);

  @media screen and (min-width: ${breakpoints.laptop}px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    box-shadow: none;
    gap: 16px;
    margin-top: 47px;
  }
`

const BreadcrumbText = styled.span`
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${defaultTheme.colors.primaryText};
  margin: 0px 8px 0px 8px;
`

const Breadcrumb = styled.div`
  align-items: center;
`

const Divider = styled.span`
  color: ${defaultTheme.colors.borderTable};
`

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`
const TitleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Oswald, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 48px;
  color: ${defaultTheme.colors.almostBlack};
  margin-bottom: 18px;
`

const HeaderRow = styled.div`
  background: ${defaultTheme.colors.rowFeaturedBackground};
  box-shadow:
    inset 0px -1px 0px ${defaultTheme.colors.borderTable},
    inset 0px 1px 0px ${defaultTheme.colors.borderTable};

  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
  padding: 8px 32px;
`

interface HeaderRowProps {
  width?: string
}
const ColHeader = styled.div<HeaderRowProps>`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  display: flex;
  align-items: center;
  text-transform: uppercase;
  width: ${(p) => p.width};
  color: ${defaultTheme.colors.columnBorder};
  padding-right: 24px;
`

const Container = styled.div`
  height: 100%;
  width: 854px;

  background-color: ${defaultTheme.colors.componentsBackground};
  z-index: ${zIndex.modal};
  padding: 24px;
  overflow-y: auto;
`

const Title = styled.div`
  font-size: 28px;
`

const ItemsAmount = styled.span`
  color: ${defaultTheme.colors.columnBorder};
  font-weight: 400;
`

const ItemsAmountSubtitle = styled.span`
  font-family: 'Oswald';
  font-weight: 450;
  font-size: 16px;
  color: ${defaultTheme.colors.greyLight};
`

const QuantityContent = styled.p`
  text-align: right;
  width: 27px;
`
