import { ReactElement } from 'react'
import MenuItem, { IMenuItem } from './MenuItemLinks/MenuItem'
import { useMenuItems } from 'src/hooks/useMenuItems'
import { Translate } from 'src/i18n'
import ResponsiveMenu from 'src/ui-components/util/responsiveMenu'
import { navTextStyle } from './CommonStyles'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

const Navbar = (): ReactElement => {
  const menuItems: IMenuItem[] = useMenuItems()
  const items = menuItems.map((i) => (
    <MenuItem key={`menu_item_${i.text}`} {...i} />
  ))

  const collapsedHeader = (
    <div style={{ ...navTextStyle, textTransform: 'capitalize' }}>
      {Translate('more')}
    </div>
  )

  const icon = (isOpen) =>
    isOpen === true ? (
      <ExpandLess style={navTextStyle} />
    ) : (
      <ExpandMore style={navTextStyle} />
    )

  return (
    <ResponsiveMenu
      key={new Date().getTime()}
      items={items}
      gap={32}
      collapsedHeader={collapsedHeader}
      statusIcon={icon}
    />
  )
}

export default Navbar
